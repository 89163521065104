<template>
  <div class="container">
    <div class="card text-center mt-5">
      <div class="card-header bg-warning text-white">
        <h1 class="card-title mb-0">Schweizer Guides</h1>
      </div>
      <div class="card-body">
        <textarea v-model="customMessage" rows="5" class="form-control mb-3"></textarea>
        <div class="file-upload">
          <input type="file" class="form-control-file mb-3" ref="fileInput" @change="handleFileUpload" accept=".xlsx, .xls" />
          <b-button class="btn btn-success mb-3" @click="downloadZip" :disabled="!isFileUploaded">
            <b-spinner v-if="isProcessing" small type="grow"></b-spinner>
            Download ZIP & Send Emails
          </b-button>
          <div class="progress" v-if="showProgressBar">
            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" :style="{ width: progress + '%' }" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">{{ progress }}%</div>
          </div>
        </div>
        <div v-if="inconsistentPNs.length > 0" class="alert alert-danger mt-3">
          <strong>Warning!</strong> The following PN numbers have inconsistent email addresses:
          <ul>
            <li v-for="pn in inconsistentPNs" :key="pn">{{ pn }}</li>
          </ul>
        </div>
        <div v-if="logs.length > 0" class="alert alert-info mt-3">
          <strong>Logs:</strong>
          <ul>
            <li v-for="log in logs" :key="log" :class="getLogClass(log)">
              {{ log }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref } from 'vue';
import * as XLSX from 'xlsx';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { generatePDF } from '../utils/pdfGeneratorswizz';
import axios from 'axios';
const getLogClass = (log) => {
  return log.includes('PDF not generated') ? 'text-danger' : '';
};

const customMessage = ref(`Guten Tag,
Anbei senden wir Dir die Übersicht, der mit der aktuellen Lohnzahlung erfassten und abgerechneten Einsätze.

Beste Grüße
`); 



const jsonData = ref(null);
const isFileUploaded = ref(false);
const showProgressBar = ref(false);
const progress = ref(0);
const fileInput = ref(null);
const isProcessing = ref(false);
const inconsistentPNs = ref([]);
const logs = ref([]);
const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};
// Function to get the highest month from date strings
const getHighestMonth = (dateStrings) => {
    const formats = ['DD.MM.YYYY', 'M/D/YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD', 'D/M/YYYY', 'DD/MM/YYYY', 'M/D/YY'];
    
    // German month names
    const germanMonths = [
      'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
      'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
    ];

    const validDates = dateStrings.map(dateStr => {
      const date = moment(dateStr, formats, true);
      return date.isValid() ? date : null;
    }).filter(date => date !== null);

    // Sort valid dates in descending order and take the highest one
    validDates.sort((a, b) => b - a);
    const highestDate = validDates[0];

    if (highestDate) {
      const monthIndex = highestDate.month(); 
      const year = highestDate.year(); 
      return `${germanMonths[monthIndex]} ${year}`; // Return the month year string in German
    }

    return 'Ungültiges Datum'; 
  };

const handleFileUpload = (event) => {
  isProcessing.value = true;
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets['Einsätze'];
      if (worksheet) {
        jsonData.value = XLSX.utils.sheet_to_json(worksheet, { raw: false, cellFormula: false }).slice(0, 8000);
        inconsistentPNs.value = checkForInconsistentEmails(jsonData.value);
        isFileUploaded.value = true;
        isProcessing.value = false;
      } else {
        alert("Sheet 'Einsätze' not found!");
        isProcessing.value = false;
      }
    };
    reader.readAsArrayBuffer(file);
  }
};

const checkForInconsistentEmails = (data) => {
  const pnEmails = data.reduce((acc, curr) => {
    if (!acc[curr.PN]) {
      acc[curr.PN] = new Set();
    }
    acc[curr.PN].add(curr.Email);
    return acc;
  }, {});

  return Object.keys(pnEmails).filter(pn => pnEmails[pn].size > 1);
};

const sendEmail = async (recipientEmail, subject, message, attachment) => {
  const accessToken = localStorage.getItem('authToken');

  if (!accessToken) {
    console.error("Access token is null or undefined.");
    return;
  }

  const emailData = {
    message: {
      subject: subject,
      body: {
        contentType: "Text",
        content: message,
      },
      toRecipients: [
        {
          emailAddress: {
            address: recipientEmail,
          },
        },
      ],
      attachments: [attachment],
    },
  };

  try {
    const response = await axios.post('https://graph.microsoft.com/v1.0/me/sendMail', emailData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    console.log(`Email sent successfully to ${recipientEmail}:`, response.data);
  } catch (error) {
    console.error(`Error sending email to ${recipientEmail}:`, error.response ? error.response.data : error.message);
  }
};


const generatePDFsAndZip = async (data) => {
  const zip = new JSZip();

  // Extract unique PN numbers
  const uniquePNs = [...new Set(data.map(item => item.PN))];

  let processedItems = 0;
  const totalItems = uniquePNs.length;

  for (const pn of uniquePNs) {
    const hostData = data.filter(item => item.PN === pn);

    if (hostData.length > 0) {
      const host = {
        Name: hostData[0].Name,
        First: hostData[0].First,
        PN: hostData[0].PN,
        Office: hostData[0].Office,
        Email: hostData[0].Email,
        projects: hostData,
      };

      if (host.Email === '0') {
        logs.value.push(`Skipping host: ${host.Name} ${host.First} due to Email being '0'`);
        processedItems++;
        progress.value = Math.round((processedItems / totalItems) * 100);
        continue;
      }

      const pdfData = await generatePDF(host);

      if (pdfData) {
        const validDates = host.projects
          .filter(project => parseFloat(project.Delta) !== 0)
          .map(project => project['Date ']);

        const highestMonth = getHighestMonth(validDates);

        const fileName = `${host.Name}_${host.PN}_Lohnabrechnung_${highestMonth}.pdf`;
        zip.file(fileName, pdfData);

        const personalizedMessage = customMessage.value.replace(/{Name}/g, host.Name);
        const pdfBase64 = arrayBufferToBase64(pdfData);
        const attachment = {
          "@odata.type": "#microsoft.graph.fileAttachment",
          name: fileName,
          contentBytes: pdfBase64,
        };

        // Send the email with the personalized message and attachment
        await sendEmail(host.Email, "Your Payroll PDF", personalizedMessage, attachment);
        // Log the email sent with the PDF name
        logs.value.push(`Email sent to ${host.Email} with attachment ${fileName}`);
      } else {
        logs.value.push(`PDF not generated for: ${host.Name} ${host.First}`);
      }
    } else {
      logs.value.push(`No data found for PN: ${pn}`);
    }

    processedItems++;
    progress.value = Math.round((processedItems / totalItems) * 100);
  }

  return zip.generateAsync({ type: 'blob' });
};


const downloadZip = async () => {
  if (jsonData.value) {
    progress.value = 0;
    showProgressBar.value = true;
    isProcessing.value = true;

    const zipContent = await generatePDFsAndZip(jsonData.value);

    saveAs(zipContent, 'Guides.zip');
    showProgressBar.value = false;
    isProcessing.value = false;
    resetFileInput();
  }
};

const resetFileInput = () => {
  if (fileInput.value) {
    fileInput.value.value = '';
    isFileUploaded.value = false;
  }
};
</script>


<style scoped>
.alert {
  margin-top: 20px;
}
.card-title {
  font-size: 24px;
  font-weight: bold;
}

.container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.card {
  width: 60%;
}
</style>
